// @flow
import React from "react";
import type { Node } from "react";

type Props = {
  class?: string,
  color: string,
  height?: string,
  width?: string,
};

function Paw(props: Props): Node {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80.44 64.84"
      height={props.height}
      width={props.width}
      fill={props.color}
      className={props.class}
    >
      <circle
        cx="8.94"
        cy="29.7"
        r="8.94"
        transform="translate(-6.58 2.85) rotate(-13.28)"
      />
      <circle
        cx="71.5"
        cy="29.7"
        r="8.94"
        transform="translate(-4.91 17.22) rotate(-13.28)"
      />
      <circle cx="27.05" cy="10.74" r="10.74" />
      <circle cx="53.47" cy="10.74" r="10.74" />
      <path d="M22.22,38.5l-.33.3c-5.9,5.41-5.65,11.67-5.65,11.67s0,.23,0,.35,0,.21,0,.33A13.43,13.43,0,0,0,19.55,60a12.8,12.8,0,0,0,9.77,4.83,11.3,11.3,0,0,0,6-1.46,19.82,19.82,0,0,0,2.9-2.07l2-1.63,2,1.63a19.92,19.92,0,0,0,2.91,2.07,11.3,11.3,0,0,0,6,1.46A12.82,12.82,0,0,0,60.89,60a13.48,13.48,0,0,0,3.31-8.86c0-.12,0-.23,0-.33s0-.23,0-.35.25-6.26-5.65-11.67l-.34-.3a74,74,0,0,0-11.51-8,13.1,13.1,0,0,0-13,0A75.1,75.1,0,0,0,22.22,38.5Z" />
    </svg>
  );
}

export default Paw;
